/**
 *
 * Custom function
 *
 */

(function($) {

	/*
	 *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
	 */

	function allClick() { 
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').click(function(event) {
				event.preventDefault();
			});

			if ($this.find('a').attr('target') == '_blank') {
				$this.click(function() {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.click(function() {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	/*
	 *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
	 */

	function loadAsynchronousImage() {

		$('*[data-loadimg]').each(function() {
			var box = $(this),
				src = box.attr('data-loadimg'),
				alt = '',
				img = new Image();

			if (box.attr('data-alt')) {
				alt = box.attr('data-alt');
			}

			$(img).load(function() {
				box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
				$('img', box).hide().fadeIn();

			}).attr('src', src);
		});

		$('*[data-loadbackground]').each(function() {
			var box = $(this),
				src = box.attr('data-loadbackground');

			box.css({
				'background-image': 'url(' + src + ')',
				'background-repeat': 'no-repeat',
				'background-position': 'top center'
			});

			if (box.has(['data-position'])) {
				box.css({
					'background-position': box.attr('data-position'),
					'background-image': 'url(' + src + ')',
					'background-repeat': 'no-repeat'
				});
			} else {
				box.css({
					'background-position': 'top center',
					'background-image': 'url(' + src + ')',
					'background-repeat': 'no-repeat'
				});
			}

			if (box.attr('data-repeat') == 'repeat') {
				box.css({
					'background-repeat': 'repeat'
				});
			} else {
				box.css({
					'background-repeat': 'no-repeat'
				});
			}
		});
	}


	/*
	 *	Funzione per la gestione dei bottoni "condividi" legati ai social
	 */

	function socialOpen() {
		$('[data-socialurl]').each(function() {
			var $this = $(this),
				url = $this.attr('data-socialurl');

			$this.click(function() {
				window.open(url, '', 'width=500, height=500');
			});

		});
	}

	function trackAdwordsCampaign() {
		//$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
	}


	/*
	 *	Funzione per l'apertura di una finestra modale tramite cookie
	 */

	function openModal() {
		if($('#onloadmodal').length) {
			if (!$.cookie("openmodal")) {
				$('#onloadmodal').modal();
				$.cookie("openmodal", 'no', {
					path: '/'
				});
			}
		}
	}



	/*
	 *	Funzione per l'apertura di un iframe all'interno di una finestra modale
	 */

	function iframeModalOpen() {
		$('.videoModal').each(function() {
			// impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
			$('.modalButton').on('click', function(e) {
				var src = $(this).attr('data-src');
				var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
				var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

				var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

				// stampiamo i nostri dati nell'iframe
				$(".videoModal iframe").attr({
					'src': src,
					'height': height,
					'width': width,
					'allowfullscreen': ''
				});
			});

			// se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
			$(this).on('hidden.bs.modal', function() {
				$(this).find('iframe').html("");
				$(this).find('iframe').attr("src", "");
			});
		});
	}

	/*
	 *	Funzione per la formattazione delle tabelle con classe listino
	 *	tramite le classi di bootstrap
	 */

	function responsiveTable() {

		$('.entry-content table').each(function() {
			var $this = $(this);
			$this.addClass('table');
			$this.wrap('<div class="table-responsive"></div>');
		});

		if ($(window).width() <= 767) {

			$('#primary .table-responsive').prepend(
				'<span class="angle-scroll-left">' +
				'<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
				'</span>'
			);

			$('#primary .table-responsive').on('scroll', function(event) {
				var angleScrollLeft = $('.angle-scroll-left');

				if ($(this).scrollLeft() >= 200) {
					angleScrollLeft.addClass('fade-out');
				} else {
					angleScrollLeft.removeClass('fade-out');
				}
			});

			$('#primary').find('.table-responsive').each(function() {
				var table = $(this);
				$('.angle-scroll-left').click(function(event) {
					table.animate({
						scrollLeft: '+=120'
					}, 200);
				});
			});
		}
	}

	/*
	 *	Funzione per l'inizializzazione della mappa
	 */
	function initializeGmap() {
		$('*[data-location]').each(function() {
			var map = $(this),
				address = map.attr('data-location');
			map.setGMap({
				address: address
			});
		});
	}


	/**
	 * Funzione per l'attivazione del menu responsive
	 */

	function responsiveMenu(viewportSize) {
		$('.main-navigation').find('a[href="#"]').each(function() {
			$(this).click(function(event) {
				event.preventDefault();
			});
		});

		//if ($(window).width() <= viewportSize) { // se minore o uguale a 1199px di default
			var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");
			var fixedMenu = $('#js-fixed-menu')[0];
			var buttonOpen = $("#js-menu-offcanvas-button-open");
			if (!fixedMenu) return;

			var stickyHeader = new Waypoint.Sticky({
				element: fixedMenu
			});

			$('.main-navigation').find('a[href="#"]').each(function() {
				$(this).click(function(event) {
					$('ul.sub-menu').removeClass('open');
					//$(this).next().toggleClass('open');
				});
			});

			$("#js-menu-offcanvas-button-open").click(function() {
			
				if ( menuPrimaryOffcanvas.hasClass('offcanvas-open') ) {
					menuPrimaryOffcanvas.removeClass('offcanvas-open');
					buttonOpen.removeClass('closed');
					$('#js-fixed-menu').removeClass('ex-fixed');
				} else {
					menuPrimaryOffcanvas.addClass("offcanvas-open");
					buttonOpen.addClass('closed');
					$('#js-fixed-menu').addClass('ex-fixed');
				}
			});

			$("#js-menu-offcanvas-button-close").click(function() {
				menuPrimaryOffcanvas.removeClass('offcanvas-open');
			});
		//} else {
			// var stickyHeader = new Waypoint.Sticky({
			//     element: $('#js-header')[0],
			//     // offset: -250 // l'offset è utile se non vuoi far partire subito fisso il menu
			// });
		//}
	}


	/*
	 *  Funzioni per PhotoSwiper
	 */
	function addImageSizeForPhotoSwiper() {
		var thumbs = $('img[data-big-size]');
		thumbs.each(function(index) {
			if ($(this).parent('a').length > 0) {
				var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
				if (isImageLink) {
					big_size = $(this).data('big-size');
					link = $(this).parent('a');
					link.attr('data-size', big_size);
					link.wrap('<div class="single-image"></div>');
				}
			}
		});
	}

	function initPhotoSwiper(selector) {
		var galleryItems = $(selector);
		var pswpItems = [];
		galleryItems.each(function(index) {
			var dimensions = $(this).find('a').data('size').split('x');
			var width = parseInt(dimensions[0], 10);
			var height = parseInt(dimensions[1], 10);

			myImage = {
				src: $(this).find('a').attr('href'),
				w: width,
				h: height,
				msrc: $(this).find('img').attr('src')
			};
			 
			pswpItems.push(myImage);
		});


		galleryItems.each(function(index) {
			$(this).click(function(evt) {
				evt.preventDefault();
				openPhotoSwipe(pswpItems, index);
			});
		});
	}

	function openPhotoSwipe(galleryItems, index) {
		var options = {
			index: index
		};
		var pswpElement = $('.pswp')[0];

		var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
		gallery.init();
	}


	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").click(function() {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {

					if(target.attr('data-paddingTopScroll')) {
						var adjust = target.attr('data-paddingTopScroll');
					}
					else {
						var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
					}
					
					$('html, body').animate({
						scrollTop: target.offset().top - adjust
					}, 1000);
					
					return false;
				}
			}
		});
	}

	function startOwl(selector) {
		$(selector).each(function(arguments) {
			var owl = $(this);
			var params = {
				nav : owl.data('owl-navigation'),
				navSpeed : owl.data('owl-slide-speed'),
				autoplay : owl.data('owl-autoplay'),
				dots : owl.data('owl-dots'),
				dotsSpeed : owl.data('owl-dots-speed'),
				loop : owl.data('owl-loop'),
				autoHeight : false,
				lazyLoad : true,
				navText: [ 
					"<i class='icon-edt-arrow-left-2'></i>",
					"<i class='icon-edt-arrow-right-2'></i>"
				]
			};

			if (owl.data('owl-single-item')) {
				params['items'] = 1;
			} else {
				params['responsive'] = {
					0: {
						items: owl.data('owl-items-xs'),
					},
					768: {
						items: owl.data('owl-items-sm'),
					},
					992: {
						items: owl.data('owl-items-md'),
					},
					1200: {
						items: owl.data('owl-items-lg'),
					},
					1440: {
						items: owl.data('owl-items-xl'),
					}
				};
			}

			owl.owlCarousel(params);

			if (owl.data('owl-prev')) {
				$(owl.data('owl-prev')).click(function() {
					owl.trigger('prev.owl.carousel');
				});
			}
			if (owl.data('owl-next')) {
				$(owl.data('owl-next')).click(function() {
					owl.trigger('next.owl.carousel');
				});
			}

			$('.header-slideshow .left').click(function() {
				owl.trigger('prev.owl.carousel');
			});

			$('.header-slideshow .right').click(function() {
				owl.trigger('next.owl.carousel');
			});
		});
	}

	/*****************************************************************************************/

	function toConsole(string) {
		if ((typeof window.console == "undefined")) {
			alert(string);
		} else console.log(string);
	}

	function $j(argument) {
		var result = jQuery(argument);
		if (result.length === 0) return null;
		else return result;
	}

	function translateCountdown( langCountdown ) {

		countdown.resetLabels();
		
		if ( langCountdown == 'it' ) {
			countdown.setLabels(
				' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
				' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
				' e ',
				' '
			);
		} else if ( langCountdown == 'de' ) {
			countdown.setLabels(
				' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
				' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
				' und ',
				' '
			);
		} else if ( langCountdown == 'fr' ) {
			countdown.setLabels(
				' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
				' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
				' et ',
				' '
			);
		}

	}

	function edtCountdown(){        

		jQuery( ".edt_countdown_class" ).find('.item').each( function(){ 
			
			var dataInizio = "";
			var questo = jQuery(this);
			jQuery.post(
				url.ajax_url, 
				{
					'action': 'edita_ajax_get_start_countdown_date',
					'offerId':   questo.data('offerid'),			        
				}, 
				
				function(response){
					setInterval(function() {
						stampaCountdown( questo, response );
					}, 3600);
				}
			);
		});
	}

	function stampaCountdown( item, response ){

		jQuery( item ).find( '.pageTimer' ).empty().append(
			moment().countdown(response, countdown.DAYS|countdown.HOURS).toString()
		);        
	}

	function closePhotoswipe() {
	
		$('.pswp__button--close').click(function() {
			if($('.pswp').hasClass('pswp--open')) {
				$('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
			}
		});
	
		$(window).scroll(function() {
			if($('.pswp').hasClass('pswp--open')) {
				$('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
			}
		});
	}

	/*
	 * Funzione per utilizzare MixItUp da select
	 */

	function filterElements() {
		
		var filterSelect = '.select-offers',
			container = '.mix-filter-support';

		if ( $(container).length ) {
			var mixer = mixitup(container, {
				selectors : {
					control : '[data-mixitup-control]'
				}
			});

			$(filterSelect).on('change', function() {
				mixer.filter(this.value);
			});
		}
	}

	/**
	 * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
	 * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
	 * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
	 * ritorna un boleano.
	 */
	var mapsMobileUrl = {
		isAndroid: function() {
			return /(android)/i.test(navigator.userAgent);
		},
		isIos: function() {
			return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
		}
	};


	function injectorLinkMaps()
	{
		var buttonMaps = $('#js-fixed-menu').find('.navigator');

		if ( mapsMobileUrl.isAndroid() ) buttonMaps.attr( 'href', phpMapsMobileUrl.android );

		if ( mapsMobileUrl.isIos() ) buttonMaps.attr( 'href', phpMapsMobileUrl.ios );

		if ( ! mapsMobileUrl.isAndroid() && ! mapsMobileUrl.isIos() ) buttonMaps.hide();
	}


	function slideMobile() {
		if (!$('body').hasClass('is-mobile')) return;

		var itemcount = 1;

		$.each(mobileSize, function (index, item) {

			if ($(window).width() > 767) {
				$('.header-slideshow .itemcounter-' + itemcount + ' ' + 'img').attr({
					src: item[0]['tablet'][0],
					width: item[0]['tablet'][1],
					height: item[0]['tablet'][2],
				});
			} else {
				$('.header-slideshow .itemcounter-' + itemcount + ' ' + 'img').attr({
					src: item[0]['mobile'][0],
					width: item[0]['mobile'][1],
					height: item[0]['mobile'][2],
				});
			}

			itemcount++;
		});
	}



	function scrollHeader() {
		$('#js-fixed-menu, #js-header').scrollHide({
			scrollDelta: 10,
			scrollOffset: 110,
			scrollClass: 'scrh-header',
			scrollClassHide: 'scrh-hide',
			onScroll: function(context, msg, currentTop) {
				if (msg === 1 && currentTop !== 0) {
					$(context).addClass('scrh-up');
					$('#js-header').addClass('scrh-up');
				} else {
					$(context).removeClass('scrh-up');
					$('#js-header').removeClass('scrh-up');
				}
			}
		});
	
	}

	$j(document).ready(function() {

		allClick();
		responsiveTable();
		addImageSizeForPhotoSwiper();
		iframeModalOpen();
		responsiveMenu(2000);
		filterElements();
		injectorLinkMaps();
		// slideMobile();
		scrollHeader();
		
		$('.feedback-description').expander({
			slicePoint: 300,
			sliceOn: '</p>',
			expandText: '[ + ]',
			userCollapseText: '[ - ]'
		});
		
		startOwl('.owl-carousel');
		smoothScroll();

		closePhotoswipe();

		if ( typeof lang && lang != 'en' ) {
			translateCountdown( lang );
		}

		edtCountdown();

		$('.gform_wrapper form').each(function() {

			$(this).checkEditaForm();
		});

		
		$('.checkNewsletter').each(function() {
			$(this).checkEditaForm();
		});



		$('.video-preview').click(function(){
			$('.container-preview').addClass('opacityHidden');
			var iframe = $( this ).find('iframe');
			var src = iframe.attr('src');
			iframe.attr( 'src', src + '&autoplay=1' );
				

			$(this).on('transitionend webkitTransitionEnd oTransitionEnd', function () {
				$('.container-preview').hide();
				$('.video-camera').removeClass('hidden');
			});
		});

		// cambio tipo di appartamento selezionato in base al numero di adulti indicati
		// $('.edita-form-builder-container [name="efb-506"],.edita-form-builder-container [name="efb-505"]').find('option').each( function(){
		// 	if( $(this).val() == "monolocale_a1") {
		// 		$(this).prop('disabled', true);
		// 	}
		// });

		$('.edita-form-builder-container').on('change', 'input,select', function(e){
			var _thisForm = $(e.delegateTarget).find('form');
			var _thisField = $(e.target);
			var iTipologia = $('[name="efb-506"],[name="efb-505"]', _thisForm).first();

			if( iTipologia.length < 1 ) return;

			var nAdults = 0;
			var nChildren = 0;

			_thisForm.find('input.efb-adults,select.efb-adults').not('[disabled]').each( function(){
				var thisRoom_adults = parseInt( $(this).val());

				if( !isNaN( thisRoom_adults )) {
					nAdults += thisRoom_adults;
				}
			});

			_thisForm.find('input.efb-children,select.efb-children').not('[disabled]').each( function(){
				var thisRoom_children = parseInt( $(this).val());

				if( !isNaN( thisRoom_children )) {
					nChildren += thisRoom_children;
				}
			});

			//console.log('nAdults', nAdults);//debug
			//console.log('nChildren', nChildren);//debug

			// il monolocale lo possono selezionare solo quando ci sono:
			// fino a max 2 adulti e 1 bambino
			if( nAdults > 0 && nAdults < 3 && nChildren >= 0 && nChildren < 2 ) {
				iTipologia.find('option').each( function(){
					if( $(this).val() == "monolocale_a1") {
						$(this).prop('disabled', false);
					}
				});
			} else {
				iTipologia.find('option').each( function(){
					if( $(this).val() == "monolocale_a1") {
						$(this).prop('disabled', true);
					}
				});

				// se l'utente aveva selezinato precedentemente il monolocale_a1
				// cambio con il primo fra quelli disponibili
				if( iTipologia.val() == null || iTipologia.val().toLowerCase() == "monolocale_a1") {
					var first_avaibleValue = iTipologia.find('option:not([disabled]):first').val();
					iTipologia.val(first_avaibleValue);
				}
			}
		});

		$(window).scroll(function() {
			if ($(document).scrollTop() > 600) {
				$('.vantaggi-esclusivi').addClass('hideVantaggi');
			} else {
				$('.vantaggi-esclusivi').removeClass('hideVantaggi');
			}
		});

		// INIZIO - blocco le date di partenza e arrivo per rendere selezionabili solo i sabati
		$("input.arrivo,input.partenza").datepicker("option", "beforeShowDay", function(ladata){
			if( ladata.getDay() != 6 ) {
				return [false,"","unAvailable"];
			} else {
				return [true,"","available"];
			}
		});
		// FINE
	});


	$j(window).load(function() {
		/*
		 *	Attraverso il Google Loader carico il modulo mappa
		 */
		if ( $('body').hasClass('page-template-template-mappa') ) {
			
			$.getScript('https://www.google.com/jsapi', function() {            
				if(url.key) {
					var map_params = 'language=' + url.lingua + '&key=' + url.key;
				}
				else {
					var map_params = 'language=' + url.lingua;
				}
				
				google.load('maps', '3', {
					other_params: map_params + '&libraries=places',
					callback: function() {
						initializeGmap();
					}
				});
			});
		}

		var gallery = $('.gallery');

		if ( gallery.length ) {
			gallery.each(function( index ) {
				
				initPhotoSwiper( "#" + $( this ).attr('id') + " .gallery-item");
			});
		}

		initPhotoSwiper(".entry-content .single-image");
		initPhotoSwiper(".room-map");

		$('.section-form .form-group').matchHeight();
		
		if ( $(window).width() > 767 ) $('.container-tripFoot, .container-bookingFoot').matchHeight();
		
		
		//note(@duncanmid): feedback pager
		
		if( !$('body').hasClass('is-mobile') ) {
		
			if( $('.container-tripFoot').length > 0 ) {
				
				$('.feedback-slideshow .owl-dots').appendTo('#ta-dots');
			}
		}
	});

	$j(window).resize(function() {



	});

})(jQuery);
